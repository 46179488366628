<template>
  <b-col class="col-lg-12">
    <iq-card>
      <b-row class="row w-100">
        <div class="col-lg-8">
          <div class="iq-card iq-card-block iq-card-stretch blog blog-detail" v-for="(data, index) of TestPrep" :key="index+'1'">
            <div class="iq-card-body">
              <div class="image-block">
                <img src="https://gide-assets-prod.s3.amazonaws.com/APPS/APPGPATHPRODUCTID/95557f40-c18a-11ee-8c9b-5df34cdd7809.png" class="img-fluid rounded w-100" alt="blog-img"/>
              </div>
              <div class="blog-description mt-2" >
                <h5 class="mb-3 pb-3 border-bottom">
                  {{data.name}}
                </h5>
                <p class="vhtmlTag vueHtmlParent mt-3">
                  {{ data.discription }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Upcomming events -->
        <div class="col-lg-4 gide_right_mobile_data">
          <EventHomeList v-if="!sideBarItemsList.Activities.exludeUserRole.includes(userData.user_role)"/>
          <CareerHomeList v-if="!isMobileDevice"/>
        </div>
      </b-row>
    </iq-card>
  </b-col>
</template>
<script>
import TestPrep from "../../../FackApi/json/TestPrep.json"
import CareerHomeList from "../ListPages/Career/CareerHomeList.vue"
import EventHomeList from "../Event/EventHomeList.vue"
import SideBarMenu from "../../../Utils/sidebarMenu.js"
export default {
  name: "TestPrep",
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  mounted () {
  },
  components: {
    EventHomeList,
    CareerHomeList
  },
  data () {
    return {
      date: new Date().toDateString(),
      TestPrep: TestPrep,
      cvCardTitle: "TestPrep",
      sideBarItemsList: SideBarMenu.getSideBarMenuObjList()
    }
  }
}
</script>
<style>
.iq-card-header{
  display:none
}
</style>
